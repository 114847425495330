import * as React from 'react'; 
import Box from '@mui/material/Box';
import pe from './Footer.css';
import Logo from './logo-branca-sombra.png';
import Instagram from './ig.png';
import Facebook from './124010.png';

export default function Footer(){
    return(
        <footer>
            <div className='divisor'>
                <div className='footer-primary'>
                    <h1>Localização </h1>
                    <div class="mapouter"><div class="gmap_canvas"><iframe width="203" height="215" id="gmap_canvas" src="https://maps.google.com/maps?q=FAT%20Inform%C3%A1tica,%20R.%20Edir%20Barbosa%20-%20Xer%C3%A9m,%20Duque%20de%20Caxias%20-%20RJ,%2025245-360&t=k&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a></div></div>
                </div>
                <div className='footer-center'>
                    <img src={Logo} />
                    <p className='centralized'>Copyright FAT Informática ® 2023 | CNPJ: 41.992.446/0001-27</p>
                </div>
                <div className='footer-contact'>
                    <h3>Contato</h3>
                    <p>21 96479-6603</p>
                    <p>faleconosco@fatinfo.com.br</p>
                    <br/>
                    <a href='https://instagram.com/fat.info' target={'_blank'}><img src={Instagram} /></a>
                    <a href='https://fb.com/fatinformaticaxerem' target={'_blank'}><img src={Facebook} /></a>
                </div>
            </div>
            <br/>
        </footer>
    )
}