import * as React from 'react';
import Box from '@mui/material/Box';
import Video from './html-original.mp4';
import estilo from './Body.css';
import autoglass from './autoglass-logo-1.png';
import { useEffect, useState, useRef } from 'react';
import polimix from './logo-polimix-eb3d2fc907703d9f0102bf8f5d27fae58e80197c3684fd30a15a221cc970bca5.png';
import { gsap } from 'gsap';

export default function Body() {

const [dado, setDado] = useState("Conheça a FAT Informática")
const [subTitle, setSubtitle] = useState("Líder em assistência técnica desde 2020")
const comp = useRef()

const onEnter = ({ currentTarget }) => {
  gsap.to(currentTarget, { scale: 1.2})
}

React.useLayoutEffect(() => {
  let ctx = gsap.context(() => {
    gsap.timeline().from('.container', {duration: 10, opacity: 0, ease: 'back'})
  }, comp)

  return () => ctx.revert()
})


    return (
      <React.Fragment>
      <Box
        sx={{
          backgroundColor: '#1A1A1A',
        }}
        style={{'zIndex':'-2'}}
      >
        <div ref={comp} className='container'>
          <video style={{'width':'100%','filter':'grayscale(100%)'}} autoPlay={true} muted>
            <source src={Video} type="video/mp4" />
          </video>
          <div className="overlay">
            <h1>{dado}</h1>
            <h4>{subTitle}</h4>
          </div>
        </div>
    </Box>
    <Box className="clientes">
        <p>Nossos clientes: </p>
        <div className='clientes-box'>
            <img src={autoglass}></img>
            <img src={polimix}></img>
        </div>
    </Box>
    <br />
    </React.Fragment>
    );
  }